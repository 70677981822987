import React, { useEffect } from 'react';
import { useAuth } from '../auth';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';

export default () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
  }, []);
  return (
    <Layout>
      <Navigation fixed />
      <div className="section">
        <div className="container is-max-desktop">
          <h2 className="title">Ciao!</h2>
          Du hast dich erfolgreich ausgeloggt.
        </div>
      </div>
    </Layout>
  );
};
